import "../css/pages/servers.css";

import { AiOutlinePlus } from "react-icons/ai";
import { ImArrowRight2 } from "react-icons/im";
import jsonConfig from "../config.json";

export default function ServersPage({ user, guilds }) {
  if (!user) return (window.location.href = `${jsonConfig.address}/api/auth/login`);

  if (guilds.included.length + guilds.excluded.length === 0)
    return (
      <div id="main">
        <div className="main-header">
          <h1>Servers</h1>
        </div>

        <div className="error-container" style={{ marginTop: "50px" }}>
          <div className="no-guilds"></div>
          <h3 className="error-description">
            You don't have any servers with the <strong>manage server</strong> permission.
          </h3>
        </div>
      </div>
    );

  function mapGuilds(servers, included) {
    return servers.map(({ name, id, icon }, i) => {
      return (
        <div className={`guild`} key={id} onClick={() => (window.location.href = `/dashboard/${id}`)}>
          {!icon ? (
            <div className="default-icon">{name.charAt(0)}</div>
          ) : (
            <img src={`https://cdn.discordapp.com/icons/${id}/${icon}.png`} alt={`icon ${name}`} />
          )}
          <h2 className="name">{name.substring(0, 21) + (name.charAt(21) !== "" ? "..." : "")}</h2>
          <a
            className={included ? "" : "invite"}
            href={
              included
                ? `dashboard/${id}`
                : "https://discord.com/oauth2/authorize?client_id=709492215092740127&scope=bot%20applications.commands&permissions=2953178230"
            }
          >
            <p>{included ? `Dashboard` : `Invite`}</p>
            {included ? (
              <ImArrowRight2 style={{ margin: "5px", fontSize: "20px" }} />
            ) : (
              <AiOutlinePlus style={{ margin: "5px", fontSize: "20px" }} />
            )}
          </a>
        </div>
      );
    });
  }

  return (
    <div id="main">
      <div className="main-header">
        <h1>Servers</h1>
      </div>

      <div className="content guilds">
        {mapGuilds(guilds.included, true)}
        {mapGuilds(guilds.excluded, false)}
      </div>
    </div>
  );
}
