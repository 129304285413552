import { AutomodSettings, GeneralSettings, WarningSettings } from "./settings";

import { RiArrowDownSLine } from "react-icons/ri";
import { SettingMutation } from "../../../graphql/mutations";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";

export function Form({ config, channels }) {
  const [updateSetting] = useMutation(SettingMutation);
  const toast = useToast();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: config,
  });

  const onSubmit = async (values) => {
    console.log(dirtyFields);
    let mutationObj = {
      guildId: config.general.guildId,
      updates: [],
    };

    if (!isDirty) {
      toast({
        title: `400 Bad Request`,
        status: "warning",
        description: "No updates supplied.",
        isClosable: true,
      });
      const submitBtn = document.getElementById("submit-btn");
      submitBtn.classList.toggle("disabled");
      setTimeout(() => submitBtn.classList.toggle("disabled"), 10000);
      return;
    }
    reset(values); // set new values

    const categoryKeys = Object.keys(dirtyFields);
    categoryKeys.forEach((categoryKey) => {
      const settingKeys = Object.keys(dirtyFields[categoryKey]);
      settingKeys.forEach((settingKey) => {
        let settingValue = values[categoryKey][settingKey];
        let actionTime = 0;
        if (settingValue.modAction) {
          actionTime = settingValue.actionTime === 0 ? null : settingValue.actionTime;
          settingValue = settingValue.modAction === "do nothing" ? null : settingValue.modAction;
        }

        if (settingValue === "none") settingValue = null;
        if (settingValue === true) settingValue = "1";
        if (settingValue === false) settingValue = "0";
        //if (typeof settingValue === "string" && !isNaN(Number(settingValue))) settingValue = Number(settingValue);

        mutationObj.updates.push({
          table: categoryKey,
          setting: settingKey,
          settingValue,
          actionTime,
        });
      });
    });

    const submitBtn = document.getElementById("submit-btn");
    submitBtn.classList.toggle("disabled");
    setTimeout(() => submitBtn.classList.toggle("disabled"), 10000);

    console.log(mutationObj);
    const response = await updateSetting({ variables: mutationObj });
    const {
      data: {
        updateSetting: { title, code, status, description },
      },
    } = response;

    toast({
      title: `${code} ${title}`,
      status,
      description,
      isClosable: true,
    });
  };

  const categories = [
    { name: "general", Run: GeneralSettings },
    { name: "automod", Run: AutomodSettings },
    { name: "warning", Run: WarningSettings },
  ];

  return (
    <div id="main">
      <div className="main-header">
        <h1>Dashboard</h1>
      </div>

      <div className="content dashboard">
        <form onSubmit={handleSubmit(onSubmit)}>
          {categories.map(({ name, Run }) => (
            <div className="category" key={name}>
              <h2>{name.charAt(0).toUpperCase() + name.substring(1)} Settings</h2>
              <RiArrowDownSLine
                className="arrow"
                onClick={(event) => event.currentTarget.classList.toggle("reversed")}
              />
              <hr />
              {/* <div className={name !== "warning" ? "settings" : "warning-settings"}> */}
              <div className="settings">
                <Run control={control} setValue={setValue} getValues={getValues} section={name} channels={channels} />
              </div>
            </div>
          ))}
          <button type="submit" id="submit-btn">
            SAVE SETTINGS
          </button>
        </form>
      </div>
    </div>
  );
}
