import { InputNumberProps, MenuProps, SwitchProps } from "./propFunctions";

import { Controller } from "react-hook-form";
import { useState } from "react";

export function GeneralSettings({ control, setValue, section, channels }) {
  let arr = [
    {
      name: "Delete Warnings",
      desc: "When enabled warnings are automatically deleted after 30 days.",
      type: SwitchProps({ control, section, variable: "deleteWarnings" }),
    },
    {
      name: "Logs Channel",
      desc: "Channel where all deleted and edited messages are logged.",
      type: MenuProps({
        control,
        setValue,
        section,
        variable: "logsChannel",
        type: "radio",
        channels: channels,
        none: true,
      }),
    },
    {
      name: "Modlogs Channel",
      desc: "Channel where all kevin actions are logged.",
      type: MenuProps({
        control,
        setValue,
        section,
        variable: "modlogsChannel",
        type: "radio",
        channels: channels,
        none: true,
      }),
    },
    {
      name: "Welcome Channel",
      desc: "Channel where all members are welcomed with an image.",
      type: MenuProps({
        control,
        setValue,
        section,
        variable: "welcomeChannel",
        type: "radio",
        channels: channels,
        none: true,
      }),
    },
    {
      name: "Qotd Channel",
      desc: "Channel where the question of the day takes place.",
      type: MenuProps({
        control,
        setValue,
        section,
        variable: "qotdChannel",
        type: "radio",
        channels: channels,
        none: true,
      }),
    },
  ];

  return arr.map((setting) => (
    <div key={setting.name} className="setting">
      <h3>{setting.name}</h3>
      <p>{setting.desc}</p>
      <div className="prop">{setting.type}</div>
    </div>
  ));
}

export function AutomodSettings({ control, section }) {
  let arr = [
    {
      name: "Verbal Warnings",
      desc: "When enabled, kevin will give verbal warnings before actual warnings.",
      type: SwitchProps({ control, section, variable: "verbalWarnings" }),
    },
    {
      name: "Duplicate Messages",
      desc: "When enabled, kevin will automatically act on messages with the same content.",
      type: SwitchProps({ control, section, variable: "duplicateMessages" }),
    },
    {
      name: "Max message length",
      desc: "Max amount of characters allowed in a message. 0 to disable, 1000 recommended.",
      type: InputNumberProps({
        control,
        section,
        variable: "maxMessageLength",
        max: 3999,
      }),
    },
    {
      name: "Mass Mention",
      desc: "When enabled, kevin will automatically act on messages with 3 or more mentions.",
      type: SwitchProps({ control, section, variable: "massMention" }),
    },
    {
      name: "Advertising",
      desc: "When enabled, kevin will automatically act on messages that include advertisement.",
      type: SwitchProps({ control, section, variable: "advertising" }),
    },
    {
      name: "Profanity",
      desc: "When enabled, kevin will automatically act on messages that include profanity.",
      type: SwitchProps({ control, section, variable: "profanity" }),
    },
    {
      name: "Hoax",
      desc: "When enabled, kevin will automatically act on messages that include hoax.",
      type: SwitchProps({ control, section, variable: "hoax" }),
    },
    {
      name: "Spam",
      desc: "Messages allowed to send within 10 seconds. 0 to disable, 5 recommended.",
      type: InputNumberProps({ control, section, variable: "spam", max: 100 }),
    },
    {
      name: "Caps",
      desc: "Caps percentage allowed in a message. 0 to disable, 60 recommended.",
      type: InputNumberProps({ control, section, variable: "caps", max: 100 }),
    },
  ];

  return arr.map((setting) => (
    <div key={setting.name} className="setting">
      <h3>{setting.name}</h3>
      <p>{setting.desc}</p>
      <div className="prop">{setting.type}</div>
    </div>
  ));
}

export function WarningSettings({ control, getValues, section, setValue }) {
  let modActions = ["do nothing", "kick", "mute", "ban", "tempban"];

  let [loading, setLoading] = useState(true);
  let values = getValues(section);
  if (values && loading) setLoading(false);

  if (loading) return <></>;

  return !Array.isArray(values)
    ? ""
    : values.map((setting, index) => {
        return (
          <div className="setting" key={index + 1}>
            <h3>{index + 1 === 1 ? `${index + 1} warning` : `${index + 1} warnings`}</h3>

            <div className="prop">
              {MenuProps({
                control,
                section,
                variable: index,
                type: "radio",
                modActions,
                setValue,
              })}
            </div>

            <Controller
              control={control}
              name={`${section}.${index}.actionTime`}
              render={({ field: { onChange, value, name } }) => {
                let days = Math.floor(value / (3600 * 24));
                let hours = Math.floor((value % (3600 * 24)) / 3600);
                let minutes = Math.floor((value % 3600) / 60);

                return (
                  <div
                    className={["tempban", "mute"].includes(setting.modAction) ? "time" : "time blocked"}
                    id={`warning-${index}`}
                  >
                    <p>For</p>
                    <input
                      type="number"
                      min="0"
                      max={setting.modAction === "mute" ? "27" : "30"}
                      className="prop"
                      placeholder="0"
                      defaultValue={days}
                      onChange={(secondValue) => {
                        if (isNaN(secondValue.target.valueAsNumber)) return;
                        days = secondValue.target.valueAsNumber;
                        let seconds = days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60;
                        onChange(seconds);
                      }}
                    />
                    <p>days</p>
                    <input
                      type="number"
                      min="0"
                      max="23"
                      className="prop"
                      placeholder="0"
                      defaultValue={hours}
                      onChange={(secondValue) => {
                        if (isNaN(secondValue.target.valueAsNumber)) return;
                        hours = secondValue.target.valueAsNumber;
                        let seconds = days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60;
                        onChange(seconds);
                      }}
                    />
                    <p>hours</p>
                    <input
                      type="number"
                      min="0"
                      max="59"
                      className="prop"
                      placeholder="0"
                      defaultValue={minutes}
                      onChange={(secondValue) => {
                        if (isNaN(secondValue.target.valueAsNumber)) return;
                        minutes = secondValue.target.valueAsNumber;
                        let seconds = days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60;
                        onChange(seconds);
                      }}
                    />
                    <p>minutes</p>
                  </div>
                );
              }}
            />
          </div>
        );
      });
}
