import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import jsonConfig from "./config.json";
import reportWebVitals from "./reportWebVitals";

const link = createHttpLink({
  uri: `${jsonConfig.address}/api/graphql`,
  credentials: "include",
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
  colors: {
    blue: {
      200: "#3182CE",
    },
    red: {
      200: "#E53E3E",
    },
    green: {
      200: "#38A169",
    },
    orange: {
      200: "#DD6B20",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Router>
        <ApolloProvider client={client}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </ApolloProvider>
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
