import "../css/pages/dashboard.css";

import { Form } from "../components/pages/dashboard/form";
import LoaderComponent from "../components/other/loader";
import { dashboardPageQuery } from "../graphql/queries";
import jsonConfig from "../config.json";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

export default function DashboardPage({ user, guilds }) {
  if (!user) return (window.location.href = `${jsonConfig.address}/api/auth/login`);
  return LoadDashboard();

  function LoadDashboard() {
    const params = useParams();

    const { loading, error, data } = useQuery(dashboardPageQuery, {
      variables: { guildId: params.id },
    });

    const dashboardGuild = guilds.included.find((guild) => guild.id === params.id);

    if (!dashboardGuild) {
      return (
        <div id="main">
          <div className="main-header">
            <h1>Dashboard</h1>
          </div>

          <div className="error-container" style={{ marginTop: "50px" }}>
            <div className="no-guilds"></div>
            <h3 className="error-description">
              You don't have permission to <strong>manage</strong> this server.
            </h3>
          </div>
        </div>
      );
    } else if (!loading && !error) {
      let { getGuildConfig, getGuildChannels /*, getWeblogs*/ } = data;

      if (getGuildConfig?.general?.guildId === undefined || getGuildConfig.general.guildId !== params.id) {
        return window.location.reload();
      }

      return <Form config={getGuildConfig} channels={getGuildChannels} />;
    }
    return <LoaderComponent />;
  }
}
