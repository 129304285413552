import { gql } from "@apollo/client";

export const SettingMutation = gql`
  mutation settingMutation($guildId: String!, $updates: [SettingsType]) {
    updateSetting(guildId: $guildId, updates: $updates) {
      code
      title
      status
      description
    }
  }
`;
