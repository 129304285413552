import "../css/pages/docs.css";

import { BsArrowReturnRight } from "react-icons/bs";
import LoaderComponent from "../components/other/loader";
import { commandQuery } from "../graphql/queries";
import { useQuery } from "@apollo/client";

export default function DocsPage() {
  const { loading, error, data } = useQuery(commandQuery);

  if (!loading && !error) {
    const { getCommands } = data;
    console.log(getCommands);

    function closeOpenIcon(name, height) {
      let close = document.getElementById(`close-${name}`);
      close.classList.toggle("hidden");

      let open = document.getElementById(`open-${name}`);
      open.classList.toggle("hidden");

      let commandInfo = document.getElementById(`info-${name}`);
      !commandInfo.style.maxHeight || commandInfo.style.maxHeight === "0px"
        ? (commandInfo.style.maxHeight = height)
        : (commandInfo.style.maxHeight = "0px");
    }

    function formatOptions(cmdName, options, loop) {
      if (!options || options.length === 0) return <></>;

      let result = [];
      options.forEach((option) => {
        if (option.type === 1) {
          if (loop) {
            result.push(
              <p className="command-parameters" key={`sub-command-${loop}-${option.name}`}>
                <span style={{ fontWeight: 600 }}>Sub command:</span> /{cmdName} {loop} {option.name}{" "}
                {formatOptions(cmdName, option.options, true)}
                <br></br>
                <BsArrowReturnRight />
                <span className="sub-description">{option.description}</span>
              </p>
            );
          } else {
            result.push(
              <p className="command-parameters" key={`sub-command-${option.name}`}>
                <span style={{ fontWeight: 600 }}>Sub command:</span> /{cmdName} {option.name}{" "}
                {formatOptions(cmdName, option.options, true)}
                <br></br>
                <BsArrowReturnRight />
                <span className="sub-description">{option.description}</span>
              </p>
            );
          }
        } else if (option.type === 2) {
          let subCommands = formatOptions(cmdName, option.options, option.name);
          subCommands.forEach((subCommand) => result.push(subCommand));
        } else {
          result.push(`[${option.name}] `);
        }
      });

      if (typeof result[0] === "string" && result[0].startsWith("[") && !loop) {
        return (
          <p className="command-parameters">
            <span style={{ fontWeight: 600 }}>Parameters:</span> /{cmdName} {result}
          </p>
        );
      } else if (loop) {
        return result;
      } else {
        return <>{result.map((paragraph) => paragraph)}</>;
      }
    }

    return (
      <div id="main">
        <div className="main-header">
          <h1>Documentation</h1>
        </div>

        <div className="commands">
          {getCommands.map(({ name, description, options, cooldown, permissions }) => (
            <div
              className="command"
              key={name}
              onClick={() => {
                let height = 100;
                options.forEach((option) => {
                  if (option.type !== 2) height += 45;

                  if (option.options)
                    option.options.forEach((optionTwo) => {
                      height += 45;
                      if (optionTwo.options)
                        optionTwo.options.forEach(() => {
                          height += 45;
                        });
                    });
                });
                closeOpenIcon(name, `${height}px`);
              }}
            >
              <h2 className="command-name">/{name}</h2>
              <i className="fas fa-bars" id={`close-${name}`}></i>
              <i className="fas fa-times hidden" id={`open-${name}`}></i>

              <div className="command-info" id={`info-${name}`}>
                <p className="command-description">
                  <span style={{ opacity: 600 }}>Description:</span> {description}
                </p>
                <p className="command-cooldown">
                  <span style={{ fontWeight: 600 }}>Usage cooldown:</span> {cooldown / 1000} s
                </p>
                {permissions.length === 0 ? (
                  <></>
                ) : (
                  <p className="command-cooldown">
                    <span style={{ fontWeight: 600 }}>Default permissions:</span>{" "}
                    {permissions
                      .map((permission) =>
                        permission
                          .split(/(?=[A-Z])/)
                          .map((str, i) => (i === 0 ? str : str.toLowerCase()))
                          .join(" ")
                      )
                      .join(", ")}
                  </p>
                )}
                {formatOptions(name, options, false)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div id="main">
        <LoaderComponent />
      </div>
    );
  }
}
