import "../css/pages/home.css";

import automodBig from "./images/automodBig.webp";
import automodExtraSmall from "./images/automodExtraSmall.webp";
import automodMedium from "./images/automodMedium.webp";
import automodSmall from "./images/automodSmall.webp";
import logsBig from "./images/logsBig.webp";
import logsExtraSmall from "./images/logsExtraSmall.webp";
import logsMedium from "./images/logsMedium.webp";
import logsSmall from "./images/logsSmall.webp";
import moderationBig from "./images/moderationBig.webp";
import moderationExtraSmall from "./images/moderationExtraSmall.webp";
import moderationMedium from "./images/moderationMedium.webp";
import moderationSmall from "./images/moderationSmall.webp";
import qotdBig from "./images/qotdBig.webp";
import qotdExtraSmall from "./images/qotdExtraSmall.webp";
import qotdMedium from "./images/qotdMedium.webp";
import qotdSmall from "./images/qotdSmall.webp";
import settingsBig from "./images/settingsBig.webp";
import settingsExtraSmall from "./images/settingsExtraSmall.webp";
import settingsMedium from "./images/settingsMedium.webp";
import settingsSmall from "./images/settingsSmall.webp";
import welcomeBig from "./images/welcomeBig.webp";
import welcomeExtraSmall from "./images/welcomeExtraSmall.webp";
import welcomeMedium from "./images/welcomeMedium.webp";
import welcomeSmall from "./images/welcomeSmall.webp";

//import homePageDivs from "./home-divs.json";
//const { easyModeration, easySettings, firstDiv, logEverything, powerfulAutomod, welcomeImages } = homePageDivs;

export default function HomePage() {
  return (
    <div id="main">
      <div className="home-top">
        <div className="animated-letters">
          <img src="https://i.imgur.com/JC4GQoX.png" alt="Kevin-Bot letter k" />
          <img style={{ animationDelay: "0.3s" }} src="https://i.imgur.com/yv7GDrL.png" alt="Kevin-Bot letter e" />
          <img style={{ animationDelay: "0.6s" }} src="https://i.imgur.com/wYaXMsb.png" alt="Kevin-Bot letter v" />
          <img style={{ animationDelay: "0.9s" }} src="https://imgur.com/hezupMV.png" alt="Kevin Bot letter i" />
          <img style={{ animationDelay: "1.2s" }} src="https://i.imgur.com/VeF9bB1.png" alt="Kevin Bot letter n" />
          <hr />
        </div>

        <div>
          <h1>Kevin Bot</h1>
          <h2>A Free Bot to protect your server, upgrade activity and moderate easily.</h2>
          <div className="btns">
            <button onClick={() => window.open("https://discord.com/invite/UA2HdpT")}>Support Server</button>
            <button
              onClick={() =>
                window.open(
                  "https://discord.com/oauth2/authorize?client_id=709492215092740127&scope=bot%20applications.commands&permissions=2953178230"
                )
              }
            >
              Add Kevin
            </button>
          </div>
        </div>
      </div>
      <svg
        style={{ transform: "rotate(180deg)", zIndex: -1, position: "relative" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="var(--first-color)"
          fillOpacity="1"
          d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{
          position: "absolute",
          left: "0",
          top: "220px",
          transform: "rotate(180deg)",
          zIndex: -2,
        }}
      >
        <path
          fill="var(--second-color)"
          fillOpacity="1"
          d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>

      <div className="content">
        <div className="flex">
          <div className="flex-div flex-reverse">
            <div className="text">
              <h3>Automod</h3>
              <p>
                Kevin's <span style={{ color: "#72FF59", fontWeight: 500 }}>first</span> feature, Automod. It's one of
                the most important things a moderation bot should do. That's why Kevin has <strong>eight</strong>{" "}
                automod features: advertising, mass mention, profanity, hoax, spam, duplicate messages, max messages
                length and caps.
                <br />
                <br />
                Kevin also has a <span style={{ color: "#FCFF59", fontWeight: 500 }}>unique</span> feature:{" "}
                <strong>verbal warnings</strong>. When enabled, Kevin will give a verbal warning when an automod feature
                triggers. The next time the same feature triggers it will give an actual warning.
                <br />
                <br />
                In the warning setting category you can set the actions Kevin will make when a member reaches a certain
                number of warnings, such as: mute, kick, tempban or ban. The{" "}
                <span style={{ color: "#FF5959", fontWeight: 500 }}>maxium</span> warning count is <strong>ten</strong>.
                <br />
                <br />
                You can customise the automod settings in the automod category ⚙️ and the warning settings in the
                warning category ⚙️.
              </p>
            </div>

            <div className="images">
              <img
                src={automodSmall}
                srcSet={`${automodExtraSmall} 426w, ${automodSmall} 640w, ${automodMedium} 854w, ${automodBig} 1280w`}
                alt="automod"
              />
            </div>
          </div>

          <div className="flex-div flex-forward">
            <div className="images">
              <img
                src={welcomeSmall}
                srcSet={`${welcomeExtraSmall} 426w, ${welcomeSmall} 640w, ${welcomeMedium} 854w, ${welcomeBig} 1280w`}
                alt="welcome"
                loading="lazy"
              />
            </div>

            <div className="text">
              <h3>Welcome images</h3>
              <p>
                It's the best way to give new members a <span style={{ color: "#FF992D", fontWeight: 500 }}>warm</span>{" "}
                welcom:, <strong>welcome images</strong>. When enabled, Kevin will welcome new members in the set
                welcome channel with one of the <strong>five</strong> images.
                <br />
                <br />
                Kevin will mention the new member with a welcome image that includes details such as the member's tag,
                avatar, member count, and joined date.
                <br />
                <br />
                There are <span style={{ color: "#FF5959", fontWeight: 500 }}>big</span> future plans for welcome
                images, such as a customisable text, layout and images.
                <br />
                <br />
                You can customise the welcome channel in the general category ⚙️.
              </p>
            </div>
          </div>

          <div className="flex-div flex-reverse">
            <div className="text">
              <h3>Easy settings</h3>
              <p>
                One of the most important aspects on a bot is its settings. Members with the{" "}
                <strong>Manage Server</strong> permission can access the server's dashboard and update settings. The{" "}
                <strong>weblogs</strong> feature is currently{" "}
                <span style={{ color: "#FF5959", fontWeight: 500 }}>disabled</span>.
                <br />
                <br />
                Most bots are built to assign where slash commands can be used (channels) or by whom it can be used
                (roles). Kevin uses Discord's newest <strong>integrations settings</strong>,{" "}
                <a href="https://discord.com/blog/slash-commands-permissions-discord-apps-bots">click here</a> to learn
                more. By default, all moderation commands need a specific permission. For example,{" "}
                <i style={{ color: "#FF5959", fontWeight: 500 }}>/ban</i> needs the <strong>Ban Members</strong>{" "}
                permission. But if you want to give a member access to this command without them having this permisison,
                it's possible using the <strong>integrations settings</strong>.
                <br />
                <br />
                Settings such as the logs channel, welcome channel, automod and automatic warning actions can all be
                managed in the dashboard. Log into the dashboard, choose a server, change the settings and press{" "}
                <span style={{ color: "#FF5959", fontWeight: 600 }}>Save Settings</span>.
              </p>
            </div>

            <div className="images">
              <img
                src={settingsSmall}
                srcSet={`${settingsExtraSmall} 426w, ${settingsSmall} 640w, ${settingsMedium} 854w, ${settingsBig} 1280w`}
                alt="easy settings"
                loading="lazy"
              />
            </div>
          </div>

          <div className="flex-div flex-forward">
            <div className="images">
              <img
                src={moderationSmall}
                srcSet={`${moderationExtraSmall} 426w, ${moderationSmall} 640w, ${moderationMedium} 854w, ${moderationBig} 1280w`}
                alt="easy moderation"
                loading="lazy"
              />
            </div>

            <div className="text">
              <h3>Easy moderation</h3>
              <p>
                Admins and moderators are able to warn members of the server, who go against their rules using{" "}
                <i style={{ color: "#FF5959", fontWeight: 500 }}>/warn (member) (reason)</i>. You can check a member's
                warnings and simply remove or clear warnings through the use of smart context menus.
                <br />
                <br />
                Many moderation commands have some kind of feature which makes moderation easier. For example, if you
                accidentally warn the wrong member you can simpely remove the warning by clicking{" "}
                <strong>delete warning</strong>. The same with <i style={{ color: "#FF5959", fontWeight: 500 }}>/ban</i>{" "}
                or <i style={{ color: "#FF5959", fontWeight: 500 }}>/mute</i>.
                <br />
                <br />A cool feature of Kevin is its modactions logs. All modactions for the specified member are
                logged: warns, bans, kicks, temprole, and so on. Access the modactions against the member through{" "}
                <i style={{ color: "#FF5959", fontWeight: 500 }}>/modactions (member)</i>.
              </p>
            </div>
          </div>

          <div className="flex-div flex-reverse">
            <div className="text">
              <h3>Smart logs</h3>
              <p>
                There are two types of logs: normal logs and modlogs. Normal logs log message events such as updates,
                deletes and bulk deletes. Modlogs log all moderation actions that are done in the server.
                <br />
                <br />
                To view moderation actions that have been taken, admins can set up a <strong>modlogs channel</strong>.
                In this channel, members with permission, can view the logs that include the mod action, moderator,
                offender, and reason. Modlogs are logged in embeds with a colour. The colours are shown in order of
                severity, <span style={{ color: "#FF5959", fontWeight: 500 }}>red</span> is a high severity,{" "}
                <span style={{ color: "#FCFF59", fontWeight: 500 }}>yellow</span> is in between and{" "}
                <span style={{ color: "#72FF59", fontWeight: 500 }}>green</span> is almost no severity.
                <br />
                <br />
                If you want to search a certain modaction from a moderator or offender you can use Discord quick search
                function. Press <strong style={{ color: "#FF2D2D" }}>Ctrl + F</strong> in the modlogs channel and search
                by the member's tag or id.
                <br />
                <br />
                You can customise the logs and modlogs channel in the general category ⚙️.
              </p>
            </div>

            <div className="images">
              <img
                src={logsSmall}
                srcSet={`${logsExtraSmall} 426w, ${logsSmall} 640w, ${logsMedium} 854w, ${logsBig} 1280w`}
                alt="smart logs"
                loading="lazy"
              />
            </div>
          </div>

          <div className="flex-div flex-forward">
            <div className="images">
              <img
                src={qotdSmall}
                srcSet={`${qotdExtraSmall} 426w, ${qotdSmall} 640w, ${qotdMedium} 854w, ${qotdBig} 1280w`}
                alt="question of the day"
                loading="lazy"
              />
            </div>

            <div className="text">
              <h3>Question of the day</h3>
              <p>
                The Question of the Day is a great incentive that comes with Kevin. It's exactly what you think it is, a
                daily question which members can answer and build up points!
                <br />
                <br />
                The Question of the Day will spawn at 4 PM EST everyday, all members have one chance to answer
                correctly. If they answer the question correctly, they receive one to three points depending on their
                streak. A streak of <strong>one</strong> will give you <strong>one</strong> point, a streak of{" "}
                <strong>two</strong> will give you <strong>two</strong> points up to a maxium of <strong>three</strong>{" "}
                points. A streak of <strong>five</strong>, <strong>ten</strong>, <strong>fifteen</strong>, etc. will
                give you the <i style={{ color: "#50c878", fontWeight: "500" }}>Server Brain</i> role for{" "}
                <strong>five</strong> days.
                <br />
                <br />
                You can check your score using <i style={{ color: "#FF5959", fontWeight: 500 }}>/stats (member)</i> and
                if you want to see the top 10 scores, you can use{" "}
                <i style={{ color: "#FF5959", fontWeight: 500 }}>/qotdleaderboard</i>. The Question of the Day points
                are not global but based on the server you are in. You can answer the Question of the Day once per day
                per server.
                <br />
                <br />
                You can customise the Question of the Day channel in the general category ⚙️.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
