import "./css/main.css";
import "./css/other/header.css";
import "./css/other/navbar.css";
import "./css/other/footer.css";
import "./css/other/other.css";

import { Route, Routes } from "react-router-dom";

import DashboardPage from "./pages/dashboard";
import DocsPage from "./pages/docs";
import FooterComponent from "./components/other/footer";
import FourOneFourPage from "./pages/404";
import HomePage from "./pages/home";
import LoaderComponent from "./components/other/loader";
import NavbarComponent from "./components/other/navbar";
import PrivacyPage from "./pages/privacy";
import ServersPage from "./pages/servers";
import { Suspense } from "react";
import TermsPage from "./pages/terms";
import { menuPageQuery } from "./graphql/queries";
import { useQuery } from "@apollo/client";

function App() {
  const { loading, error, data } = useQuery(menuPageQuery);

  if (!loading && !error) {
    const { getUser, getMutualGuilds } = data;

    return (
      <Routes>
        <Route path="/" exact={true} element={Layout(<HomePage />, getUser)} />
        <Route path="/docs" element={<Suspense fallback={<>...</>}>{Layout(<DocsPage />, getUser)}</Suspense>} />
        <Route
          path="/servers"
          element={
            <Suspense fallback={<>...</>}>
              {Layout(<ServersPage user={getUser} guilds={getMutualGuilds} />, getUser)}
            </Suspense>
          }
        />
        <Route
          path="/dashboard/:id"
          element={
            <Suspense fallback={<>...</>}>
              {Layout(<DashboardPage user={getUser} guilds={getMutualGuilds} />, getUser)}
            </Suspense>
          }
        />
        <Route path="/iframe" element={<HomePage />} />
        <Route path="/privacy" element={Layout(<PrivacyPage />, getUser)} />
        <Route path="/terms" element={Layout(<TermsPage />, getUser)} />
        <Route path="*" element={Layout(<FourOneFourPage />, getUser)} />
      </Routes>
    );
  }
  return Layout(<LoaderComponent />);
}

function Layout(component, user) {
  return (
    <>
      <NavbarComponent user={user} />
      {component}
      <FooterComponent />
    </>
  );
}

export default App;
