import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
} from "@chakra-ui/react";

import { Controller } from "react-hook-form";
import { IoIosArrowDown } from "react-icons/io";

export function InputNumberProps({ control, section, variable, disabled, max }) {
  return (
    <Controller
      control={control}
      name={section + "." + variable}
      render={({ field: { onChange, value, name } }) => (
        <NumberInput
          isDisabled={disabled}
          allowMouseWheel
          min={0}
          max={max}
          name={name}
          onChange={onChange}
          defaultValue={value}
          borderColor="white"
          color="white"
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
    />
  );
}

export function SwitchProps({ control, section, variable, setValue }) {
  return (
    <Controller
      control={control}
      name={section + "." + variable}
      render={({ field: { onChange, value, name } }) => (
        <Switch
          className="prop-function"
          name={name}
          colorScheme="green"
          size="lg"
          onChange={onChange}
          defaultChecked={Number(value)}
        />
      )}
    />
  );
}

export function MenuProps({ control, section, variable, type, channels, none, modActions, setValue }) {
  return (
    <Controller
      control={control}
      name={`${section}.${variable}${modActions ? ".modAction" : ""}`}
      render={({ field: { onChange, value, name } }) => (
        <Menu closeOnSelect={false} isLazy={false} placement="top-start">
          <MenuButton className="prop-function" as={Button} colorScheme="blue" rightIcon={<IoIosArrowDown />} size="md">
            {modActions ? (!value ? "do nothing" : value) : "Choose"}
          </MenuButton>

          <MenuList
            className="special-scroll"
            minWidth="175px"
            maxWidth="300px"
            maxHeight="200px"
            overflowY="scroll"
            rounded="xl"
            borderColor="#222427"
            borderWidth="6px"
            bg="#2b2e31"
          >
            <MenuOptionGroup
              name={name}
              type={type}
              defaultValue={modActions ? (!value ? "do nothing" : value) : value === null ? "none" : value}
              onChange={(value) => {
                onChange(value);
                if (!modActions) return;

                let timeDiv = document.getElementById(`warning-${variable}`);
                if (Array.from(timeDiv.classList).includes("blocked") && ["tempban", "mute"].includes(value))
                  timeDiv.classList.toggle("blocked");
                else if (!Array.from(timeDiv.classList).includes("blocked") && !["tempban", "mute"].includes(value)) {
                  timeDiv.classList.toggle("blocked");
                  Array.from(timeDiv.getElementsByTagName("input")).forEach((inputElement) => {
                    inputElement.value = "0";
                  });
                  setValue(`warning.${variable}.actionTime`, 0);
                }
              }}
            >
              {!none ? (
                ""
              ) : (
                <MenuItemOption key="none" value="none">
                  None
                </MenuItemOption>
              )}

              {modActions
                ? modActions.map((modAction) => (
                    <MenuItemOption key={modAction} value={modAction}>
                      {modAction}
                    </MenuItemOption>
                  ))
                : channels.map((channel) => (
                    <MenuItemOption key={channel.id} value={channel.id}>
                      #{channel.name}
                    </MenuItemOption>
                  ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      )}
    />
  );
}
