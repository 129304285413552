import { gql } from "@apollo/client";

export const commandQuery = gql`
  query getCommands {
    getCommands {
      name
      description
      options {
        name
        description
        type
        options {
          name
          description
          type
          options {
            name
            description
            type
          }
        }
      }
      cooldown
      category
      permissions
    }
  }
`;

export const menuPageQuery = gql`
  query getMenuPageQuery {
    getUser {
      userId
      tag
      avatar
    }
    getMutualGuilds {
      excluded {
        id
        name
        icon
      }
      included {
        id
        name
        icon
      }
    }
  }
`;

export const dashboardPageQuery = gql`
  query getMenuPageQuery($guildId: String!) {
    getGuildConfig(guildId: $guildId) {
      general {
        guildId
        logsChannel
        modlogsChannel
        welcomeChannel
        qotdChannel
        deleteWarnings
      }
      automod {
        guildId
        duplicateMessages
        maxMessageLength
        verbalWarnings
        massMention
        advertising
        profanity
        hoax
        spam
        caps
        nsfw
      }
      warning {
        modAction
        actionTime
      }
    }
    getGuildChannels(guildId: $guildId) {
      id
      name
      type
      position
    }
    getWeblogs(guildId: $guildId) {
      user
      setting
      action
    }
  }
`;
