import { NavLink } from "react-router-dom";
import jsonConfig from "../../config.json";
import { useEffect } from "react";

export default function NavbarComponent({ user }) {
  useEffect(() => {
    const body = document.querySelector("body");
    const navbar = document.querySelector(".navbar");
    const menuBtn = document.querySelector(".menu-btn");
    const cancelBtn = document.querySelector(".cancel-btn");
    menuBtn.onclick = () => {
      navbar.classList.add("show");
      menuBtn.classList.add("hide");
      body.classList.add("disabled");
    };
    cancelBtn.onclick = () => {
      body.classList.remove("disabled");
      navbar.classList.remove("show");
      menuBtn.classList.remove("hide");
    };
    const links = navbar.getElementsByTagName("a");
    Array.from(links).forEach((link) => {
      link.onclick = () => {
        body.classList.remove("disabled");
        navbar.classList.remove("show");
        menuBtn.classList.remove("hide");
      };
    });
    window.onscroll = () => {
      window.scrollY > 50 ? navbar.classList.add("sticky") : navbar.classList.remove("sticky");
    };
  });

  return (
    <nav className="navbar">
      <div className="content">
        <div className="logo">
          <a href="https://discord.com/oauth2/authorize?client_id=709492215092740127&scope=bot%20applications.commands&permissions=2953178230">
            KevinBot
          </a>
        </div>
        <ul className="menu-list">
          <div className="icon cancel-btn">
            <i className="fas fa-times"></i>
          </div>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/docs">Docs</NavLink>
          </li>
          <li>
            <NavLink to="/servers">Servers</NavLink>
          </li>
          <li>
            <a href={user ? `${jsonConfig.address}/api/auth/logout` : `${jsonConfig.address}/api/auth/login`}>
              {user ? (
                <>
                  Logout <span style={{ opacity: "0.8", fontSize: "15px" }}>{user.tag}</span>
                  <i className="fas fa-sign-out-alt" style={{ color: "#ff0000", marginLeft: "10px" }}></i>
                </>
              ) : (
                <>
                  Login
                  <i className="fas fa-sign-in-alt" style={{ color: "#0096FF", marginLeft: "10px" }}></i>
                </>
              )}
            </a>
          </li>
        </ul>
        <div className="icon menu-btn">
          <i className="fas fa-bars"></i>
        </div>
      </div>
    </nav>
  );
}
