import "../css/pages/privacy.css";

export default function PrivacyPage() {
  return (
    <div id="main">
      <div className="privacy">
        <h1>Privacy policy</h1>

        <h2>What data Kevin collects and uses</h2>
        <p>
          Kevin mainly collects and stores guild IDs, member IDs, role IDs and channel IDs for identifying purposes. But
          also collects message content to check for duplicate messages, max message length, mass mentions, advertising,
          profanity, hoax, spam and caps. But Kevin does not store this data, it only checks the message content. Kevin
          also collects message content for the Question of the day, it checks if the message content is the same as the
          correct answer for that question. But does not store this data. It also checks message data to for the word
          "kevin", kevin will respond to certain questions containing that word, this message is not stored. Kevin also
          logs deleted messages and updated messages in the desired log channel that the guild admins can select, but
          does not store this data. If you log into Kevin's dashboard it will store your acces token and refresh token
          encrypted in the database.
        </p>

        <h2>How to remove your data</h2>
        <p>
          If you want to remove your data you can join our{" "}
          <a href="https://discord.com/invite/UA2HdpT">Support Server</a> and either contact Elijah#8888 to remove your
          data or ask to remove your data in <strong>#ask-dev</strong>.
        </p>
      </div>
    </div>
  );
}
